// Dependencies
import helpers from './helpers.js'

const compare = (a, b) => {
	if (Number(a.dataset.best) < Number(b.dataset.best)) return 1
	if (Number(a.dataset.best) > Number(b.dataset.best)) return -1
	return 0
}

const sort = () => {
	const items = document.querySelectorAll('.rider')
	const itemsArray = Array.from(items)
	const sorted = itemsArray.sort(compare)
	for (let [index, rider] of sorted.entries()) {
		const oldPosition = rider.dataset.position
		const newPosition = index + 1
		rider.dataset.position = newPosition
		rider.style.setProperty('--position', newPosition)
		rider.querySelector('.rider__position').textContent = newPosition
		// Highlight
		if (newPosition < oldPosition) {
			rider.classList.remove('rider--updated')
			rider.classList.add('rider--advanced')
			setTimeout(() => {
				rider.classList.remove('rider--advanced')
			}, 5000)
		}
	}
}

const updateResults = () => {

	// const dataElement = document.querySelector('[data-event]')
	helpers.ajax.get('/controllers/actions/run.latest')
	.then(response => {

		// Start broadcast
		if (response.broadcasting) {
			if (document.body.classList.contains('paused')) {
				window.location.reload()
			}
		}

		// Stop broadcast
		if (!response.broadcasting) {
			if (!document.body.classList.contains('paused')) {
				window.location.reload()
			}
		}

		// Paused broadcast
		if (!response.broadcasting) {
			if (document.body.classList.contains('paused')) {
				return
			}
		}

		// Do nothing if there's no response
		if (!response) return

		// Loop through riders
		for (const rider of response.riders) {

			// Set default as no relevent update
			let update = false

			// Get rider's row
			const row = document.querySelector(`[data-id="${rider.id}"]`)
			if (!row) continue

			// Compare and update last run
			const lastElement = row.querySelector('.rider__last')
			if (lastElement.dataset.value != rider.lastRun) {
				lastElement.dataset.value = rider.lastRun
				lastElement.querySelector('.number--ft').textContent = rider.lastRunFormatted.feet
				lastElement.querySelector('.number--in').textContent = rider.lastRunFormatted.inches
				update = true
			}

			// Compare and update best run
			const bestElement = row.querySelector('.rider__best')
			if (bestElement.dataset.value != rider.bestRun) {
				row.dataset.best = rider.bestRun
				bestElement.dataset.value = rider.bestRun
				bestElement.querySelector('.number--ft').textContent = rider.bestRunFormatted.feet
				bestElement.querySelector('.number--in').textContent = rider.bestRunFormatted.inches
				update = true
			}

			// Highlight
			if (update) {
				row.classList.add('rider--updated')
				setTimeout(() => {
					row.classList.remove('rider--updated')
				}, 5000)
			}

		}

		sort()

	})

}

document.addEventListener('DOMContentLoaded', async () => {

	// Sort results on page load
	sort()

	// Update results every x milliseconds
	setInterval(updateResults, 1000)

})